//fullpage js
var vW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
var vH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
var fpEnabled = false;
var isMobile = vW < 992;

function debounce(func) {
    var timer;

    return function (event) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, 100, event);
    };
}

function hashChangeListener(forcedLink) {
    var link = forcedLink || document.location.href;

    for (var iterator = 0; iterator < menhuEls.length; iterator++) {
        if (menhuEls[iterator].classList.contains('choosen')) {
            menhuEls[iterator].classList.remove('choosen')
        }
        if (menhuEls[iterator].href == link) {

            var tempCounter = iterator;
            if (tempCounter !== counter) {
                counter = tempCounter;
                menhuEls[iterator].classList.add('choosen');
            }
        }
    }
}

var menuElements = document.getElementById('main-menu').getElementsByTagName('a');

var initFullpage = function (w) {
    fpEnabled = true;
    if (w >= 992) {

        $('#fullpage').fullpage({
            sectionSelector: '.vertical-scrolling',
            slideSelector: '.horizontal-scrolling',
            anchors: ['homepage', 'relacja', 'agenda', 'prelegenci', 'gwiazda', 'miejsce', 'kontakt', 'partnerzy'],
            verticalCentered: false,
            controlArrows: true,
        });
    }

    else {
        $('#fullpage').fullpage({
            sectionSelector: '.horizontal-scrolling',
            anchors: ['homepage', 'relacja', 'agenda', 'prelegenci', 'gwiazda', 'miejsce', 'kontakt', 'partnerzy'],
            verticalCentered: false,
            controlArrows: true,
            autoScrolling: false,
            fitToSection: false,
            offsetTop: 70,
            onLeave: function (index, nextIndex, direction) {
                var leavingSection = $(this);
                hashChangeListener(menuElements[nextIndex - 1].href);
            }
        });



        for (var i = 0; i < menuElements.length; i++) {
            var tempHash = menuElements[i].href;
            menuElements[i].href = tempHash.replace('homepage/', '');
        }
    }
}

var destroyFn = function (w) {

    if (fpEnabled) {
        $.fn.fullpage.destroy('all');
    }
}

var scrollPeople = function (h, obj) {
    if (obj) {
        if (h < obj.offsetHeight - 20) {
            obj.parentNode.classList.add('scroll-div');
        }
        else {
            if (obj.parentNode.classList.contains('scroll-div')) {
                obj.parentNode.classList.remove('scroll-div');
            }
        }
    }
}

var innerScroll = function (h, scrollObj, scrollBtn, scrollRelated) {
    if (scrollObj && scrollRelated) {
        if (h < scrollRelated.offsetHeight - 20) {
            scrollObj.classList.add('scroll-div');
            scrollBtn.classList.add('show-scroll');

            scrollBtn.addEventListener('click', function () {
                scrollObj.scrollBy({
                    top: 999,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        }
        else {
            if (scrollObj.classList.contains('scroll-div')) {
                scrollObj.classList.remove('scroll-div');
                scrollBtn.classList.remove('show-scroll');
            }
        }
    }
}

var menu = function () {
    var burgerEl = document.getElementById('burger-click');
    var mainNav = document.getElementById('main-navigation');

    burgerEl.addEventListener('click', function () {
        if (mainNav.classList.contains('open')) {
            mainNav.classList.remove('open');
        }
        else {
            mainNav.classList.add('open');
        }
    });

    document.addEventListener('click', function (e) {
        if (mainNav.classList.contains('open') === true) {
            if (e.target.id !== 'main-navigation' && e.target.id !== 'burger-click') {
                mainNav.classList.remove('open');
            }
        }
    });
}

var menhuEls;
var counter;

var menuChoosen = function () {
    menhuEls = document.getElementsByClassName('main-menu')[0].getElementsByTagName('a');
    window.addEventListener('hashchange', () => hashChangeListener());
}

var prelegentsAction = function () {
    var prelegents = document.getElementsByClassName('people')[0].getElementsByClassName('image');
    var counter;

    var handler = function (arr, n) {
        return function () {
            var tempCounter = n;

            if (counter !== undefined && arr[counter].nextElementSibling.classList.contains('show')) {
                arr[counter].nextElementSibling.classList.remove('show');
            }

            if (tempCounter !== counter) {
                counter = tempCounter;
                arr[n].nextElementSibling.classList.add('show');

                arr[n].nextElementSibling.getElementsByClassName('close')[0].addEventListener('click', function () {
                    arr[n].nextElementSibling.classList.remove('show');
                    counter = undefined;
                });
            }
        }
    }

    for (var iterator2 = 0; iterator2 < prelegents.length; iterator2++) {
        prelegents[iterator2].addEventListener('click', handler(prelegents, iterator2));
    }
}

var hotel = function () {
    var showHotel = document.getElementsByClassName('show-hotel')[0];
    var container = document.getElementById('miejsce-info').getElementsByClassName('container')[0];
    var containerHidden = document.getElementById('miejsce-info').getElementsByClassName('container-hidden')[0];
    showHotel.addEventListener('click', function () {
        container.classList.add('show');
        containerHidden.classList.add('show');
    });
    var closeEl = document.getElementById('miejsce-info').getElementsByClassName('close')[0];
    closeEl.addEventListener('click', function () {
        container.classList.remove('show');
        containerHidden.classList.remove('show');
    });
}

var pushToHistory = function () {
    var home = '/#homepage/';
    var newHref;
    if (isMobile && window.location.href.indexOf(home) !== -1) {
        newHref = window.location.href.replace('/#homepage/', '/#');
        history.pushState(null, null, newHref);
    }
    else if (!isMobile && window.location.href.indexOf(home) === -1) {
        newHref = window.location.href.replace('/#', '/#homepage/');
        history.pushState(null, null, newHref);
    }
}

var gallery = function () {
    var mediaList = $('.media-list'),
        loadMoreBtn = $('.gallery-load-more'),
        videoItem = $('.media-item-video'),
        magnificPopup = $.magnificPopup.instance,
        hammerElem,
        currentLastItem = 178,
        mediaLoadCounter = 20,
        maxItems = 197,
        swipeAdded = false;

    function mediaItemTemplate(itemCounter) {
        itemCounter = itemCounter < 10 ? '00' + itemCounter : itemCounter < 100 ? '0' + itemCounter : itemCounter;
        return '<li class="media-item hidden"><a href="/bundles/portal/images/galeria/full-size/pharmacybusiness_' + itemCounter + '.jpg" style="background-image:url(/bundles/portal/images/galeria/min/pharmacybusiness_' + itemCounter + '.jpg)"></a></li>';
    }

    function loadMoreMedia() {
        var i = currentLastItem - 1;
        var itemsToAdd = [];
        for (; i >= currentLastItem - mediaLoadCounter; i--) {
            if (i > maxItems) {
                loadMoreBtn.fadeOut();
                break;
            }

            itemsToAdd.push(mediaItemTemplate(i));
        }

        currentLastItem = i <= maxItems ? currentLastItem -= mediaLoadCounter : i;
        mediaList.append(itemsToAdd);

        setTimeout(function () {
            mediaList.find('.hidden').removeClass('hidden');
        }, 300);
    }

    mediaList.magnificPopup({
        removalDelay: 300,
        mainClass: 'mfp-with-zoom mfp-fade',

        callbacks: {

            elementParse: function (item) {
                if (item.src === '#video-popup') {
                    item.type = 'inline';
                }
                if (item.src.indexOf('youtube') > -1) {
                    item.type = 'iframe';
                }
            },

            open: function () {
                if (!swipeAdded) {
                    setTimeout(function () {
                        var galleryWrapper = document.querySelector('.mfp-gallery');
                        saveHammerWrapper(galleryWrapper);
                        addSwipeEvents(galleryWrapper);
                    }, 100);
                    swipeAdded = true;
                }
            },

            beforeClose: function () {
                if (swipeAdded) {
                    var galleryWrapper = document.querySelector('.mfp-gallery');
                    removeSwipeEvents(galleryWrapper);
                    swipeAdded = false;
                }

            }
        },

        type: 'image',
        delegate: 'a',
        gallery: {
            enabled: true,
            tPrev: 'Poprzednie',
            tNext: 'Następne',
            tCounter: '%curr% / %total%'
        }

    });

    loadMoreBtn.on('click', loadMoreMedia);

    function saveHammerWrapper(elem) {
        hammerElem = Hammer(elem);
    }

    function addSwipeEvents() {

        if (hammerElem) {
            hammerElem.on("swipeleft", function () {
                magnificPopup.next();
            });

            hammerElem.on("swiperight", function () {
                magnificPopup.prev();
            });
        }
    }

    function removeSwipeEvents(elem) {
        hammerElem.off("swiperight swipeleft");
    }

}

var showGallery = function () {
    var show = document.getElementsByClassName('show-gallery')[0];
    var container = document.getElementById('galeria-info').getElementsByClassName('container')[0];
    var containerHidden = document.getElementById('galeria-info').getElementsByClassName('container-hidden')[0];
    show.addEventListener('click', function () {
        container.classList.add('show');
        containerHidden.classList.add('show');

        $.fn.fullpage.setAllowScrolling(false);
    });
    var closeEl = document.getElementById('galeria-info').getElementsByClassName('close')[0];
    closeEl.addEventListener('click', function () {
        container.classList.remove('show');
        containerHidden.classList.remove('show');
    });
}

destroyFn(vW);
initFullpage(vW);
scrollPeople(vH, document.getElementsByClassName('photo')[0]);
innerScroll(vH, document.getElementById('agenda-info').getElementsByClassName('container')[0], document.getElementById('agenda-info').getElementsByClassName('scroll-icon')[0], document.getElementById('agenda-info').getElementsByClassName('container')[0].getElementsByClassName('main')[0]);
menu();
menuChoosen();
prelegentsAction();
hotel();
pushToHistory();
gallery();
showGallery();


//window resize
window.addEventListener(
    'resize',

    debounce(function (e) {
        var tempH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var tempW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var needMb = tempW < 992;

        innerScroll(tempH, document.getElementById('agenda-info').getElementsByClassName('container')[0], document.getElementById('agenda-info').getElementsByClassName('scroll-icon')[0]);
        scrollPeople(tempH, document.getElementsByClassName('photo')[0]);

        if (needMb !== isMobile) {
            isMobile = needMb;
        }
        else {
            return;
        }

        destroyFn(tempW);
        initFullpage(tempW);
    })
);